import React from 'react'
const Timer = Loader.loadBusinessComponent('BaseLibComponents', 'Timer')
const PointSelect = Loader.loadBusinessComponent('BaseLibComponents', 'PointSelect')
const ColligateSearch = Loader.loadComponent('SearchInput')
const SearchButton = Loader.loadBusinessComponent('BaseLibComponents', 'SearchButton')
const VehicleColorView = Loader.loadBusinessComponent('BaseLibComponents', 'VehicleColor')
const SelectView = Loader.loadBusinessComponent('BaseLibComponents', 'Select')
const ScoreSlider = Loader.loadBusinessComponent('BaseLibComponents', 'ScoreSlider')
const RadioGroup = Loader.loadBusinessComponent('BaseLibComponents', 'RadioGroup')

let { sex, nonMotorVehicleModel, nonVehicleColor, head, upperColorType, upperColorLocal } = Dict.map
nonMotorVehicleModel.unshift({
  value: null,
  label: '全部'
})
export default class Search extends React.Component {
  /**
   * @desc 编辑查询条件
   */
  change = (options={}, needSearch = true) => {
    this.props.mergeSearchData(options, needSearch)
  }

  /**
   * @desc 切换时间Tabs
   */
  render(){
    const { searchData = {}, url = '', isSearch, handFrame, frameUrl, rects } = this.props
    return (
      <div className='baselib-search-wrapper'>
        {/* <div className='kw-search'>
          <ColligateSearch 
            width='100%'
            placeholder='请输入车牌号搜索'
            onChange={plateNo => this.change({ plateNo }, false)}
            onPressEnter={e => this.change({ plateNo: e.target.value })}
          />
        </div> */}
        <SearchButton 
          // type='vehicle'
          type='nonVehicle'
          url={url}
          change={this.change}
          changeUrl={this.props.changeUrl}
          isNonMotorized={true}
          handFrame={handFrame}
          frameUrl={frameUrl}
          rects={rects}
        />
        {isSearch && <ScoreSlider 
          value={searchData.score}
          change={this.change}
        />}
        <div className='small-title'>图库筛选 :</div>
        <Timer 
          value={searchData.timerTabsActive}
          change={this.change}
          onOk={this.change}
          startTime={searchData.startTime}
          endTime={searchData.endTime}
          needSearch={true}
        />
        <PointSelect 
          onChange={this.change}
          selectList={searchData.cameraIds}
        />
        <VehicleColorView
          data={nonVehicleColor}
          label='车辆颜色'
          iconFont='icon-M_Files_CarAlarm'
          value={searchData.vehicleColor}
          name='vehicleColor'
          change={this.change}
        />
        <SelectView 
          data={nonMotorVehicleModel}
          label='车辆类型'
          iconFont='icon-S_Photo_Type'
          placeholder='请选择机动车类型'
          value={searchData.nonMotorVehicleModel}
          name='nonMotorVehicleModel'
          change={this.change}
        />
        <RadioGroup
          data={sex}
          label='性别'
          iconFont='icon-S_AID_Sex'
          value={searchData.sex}
          name='sex'
          change={this.change}
        />
        <RadioGroup
          data={head}
          label='头部特征'
          value={searchData.head}
          iconFont='icon-S_AID_SunGlasses'
          name='head'
          change={this.change}
        />  
        <VehicleColorView
          data={upperColorLocal}
          label='上身颜色'
          iconFont='icon-S_Photo_Theme'
          value={searchData.upColor}
          name='upColor'
          change={this.change}
        /> 
        <RadioGroup
          data={upperColorType}
          label='上身纹理'
          iconFont='icon-S_AID_ClothesSolid'
          value={searchData.upperTexture}
          name='upperTexture'
          change={this.change}
        /> 
      </div>
    )
  }
}