/**
 * @desc 非机动车图库
 * @author tlzj
 */

import React, { Component } from 'react'
import Wrapper from './components/wrapper'

class NonVehicleLibrary extends Component {
  render(){
    return <Wrapper />
  }
}
export default NonVehicleLibrary